<template>
  <div class="w-full h-auto">
    <div v-if="loading" class="absolute w-full justify-center top-1/3 left-0">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>

    <TotalComplaints
      :total="count"
      title="Total de Quejas"
      body="Quejas clasificadas"
    />

    <!-- Filtro de búsqueda -->
    <div>
      <div class="p-3 bg-white rounded-xl shadow-lg mx-4">
        <div class="grid grid-cols-2" @click="showFilters = !showFilters">
          <div class="flex items-center gap-4">
            <a-icon type="filter" class="text-lg ml-2"></a-icon>
            <h2 class="text-left text-lg text-gray-600">
              <b>Filtros de búsqueda</b>
            </h2>
          </div>
          <span
            class="h-5 w-5 m-2 flex justify-center items-center transition justify-self-end"
            :class="{ 'transform rotate-180': showFilters }"
          >
            <a-icon type="caret-down" class="text-xl my-auto" />
          </span>
        </div>
        <a-divider v-if="showFilters" class="mt-2 mb-2"></a-divider>
        <div
          class="flex gap-5 flex-wrap relative w-full border-box"
          v-show="showFilters"
        >
          <div
            @keypress.enter="!disabled || !disableButton ? onSearch() : ''"
            class="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 relative"
            :class="
              management && optionsProducts.length !== 0
                ? ' xl:grid-cols-4'
                : ''
            "
          >
            <BaseSelect
              ref="company"
              id="entidad"
              label="Entidad"
              :selectOptions="formOptions.optionsCompanies"
              v-model="company"
              :loading="loading"
              :disabled="$store.state.session.user.person.position === 21"
            />
            <BaseSelect
              v-if="optionsProducts.length === 0"
              ref="product"
              id="producto "
              label="Producto"
              :selectOptions="formOptions.optionsProducts"
              v-model="form.producto_cod"
              :loading="loading"
            />
            <BaseSelect
              v-if="optionsReasons.length === 0"
              ref="reason"
              id="reason"
              label="Motivo"
              :selectOptions="formOptions.optionsReasons"
              v-model="form.macro_motivo_cod"
              :loading="loading"
            />
            <BaseSelect
              v-if="optionsProducts.length > 0"
              ref="product"
              id="producto"
              label="Producto"
              :selectOptions="optionsProducts"
              v-model="form.producto_cod"
              :loading="loading"
            />
            <BaseSelect
              v-if="optionsReasons.length > 0"
              ref="reason"
              id="reason"
              label="Motivo"
              :selectOptions="optionsReasons"
              v-model="form.macro_motivo_cod"
              :loading="loading"
            />

            <BaseInput
              type="text"
              label="Código de queja / Id. radicado"
              placeholder="Ej: 26598965472981"
              v-model="form.codigo_queja"
            />

            <BaseInput
              type="text"
              label="Palabra clave"
              placeholder="Queja, producto o motivo"
              v-model="form.search"
              v-if="!duplicate"
            />
            <BaseSelect
              ref="Instancia de recepción"
              id="Instancia de recepción"
              label="Instancia de recepción"
              :selectOptions="formOptions.optionsReception"
              v-model="form.insta_recepcion"
              v-if="!duplicate"
              :loading="loading"
            />
            <BaseRangeDate
              ref="Fecha de creacion"
              label="Fecha de creación"
              v-if="!duplicate"
              @notify-dates="setRangeDate($event)"
            />
            <BaseSelect
              ref="identificacion"
              id="identification"
              label="Tipo de identificación"
              :selectOptions="formOptions.optionsIdentificationType"
              v-model="form.tipo_id_cf"
              :loading="loading"
            />
            <BaseInput
              type="text"
              label="Nombre"
              placeholder="Ej: Fulano Lopez"
              v-model="form.nombre_cf"
            />
            <BaseInput
              type="text"
              label="Nombre del apoderado"
              placeholder="Ej: Sutano Ramirez"
              v-model="form.apoderado_nombre"
            />
            <div>
              <BaseInput
                type="text"
                label="No. de identificación"
                placeholder="Ej: 1144578546"
                v-model="form.numero_id_cf"
                :identification="form.tipo_id_cf"
              />
            </div>

            <div>
              <BaseInput
                ref="email_user"
                type="email_user"
                label="Correo electrónico"
                placeholder="username@example.com"
                v-model="form.email_user"
                v-if="!duplicate"
              />
              <p
                v-if="this.form.email_user != '' && feedback"
                class="mt-2 text-xs text-red-500"
              >
                {{ feedback ? feedback : "" }}
              </p>
            </div>
            <div>
              <BaseRadio
                id="habeas_data"
                :name="'habeas_data'"
                label="Queja relacionada con motivo de habeas data"
                :radioOptions="[
                  { id: true, name: 'Sí' },
                  { id: false, name: 'No' },
                ]"
                v-model="form.check_habeas_data"
              />
            </div>
          </div>
          <div class="w-full flex justify-end self-end gap-4">
            <a-button
              class="w-1/2 gap-2 sm:mt-auto mt-3 lg:w-1/6"
              type="danger"
              ghost
              shape="round"
              @click="clearFilters"
            >
              Limpiar
            </a-button>

            <a-button
              :loading="(searching = loading)"
              type="primary"
              shape="round"
              @click="onSearch"
              class="w-1/2 gap-2 sm:mt-auto mt-3 lg:w-1/6"
              :disabled="disableButton && disabled"
            >
              <a-icon type="search" v-if="!searching"></a-icon>
              {{ searching ? "BUSCANDO..." : "BUSCAR" }}
            </a-button>
          </div>
          <!-- <div
            v-if="loading"
            class="absolute w-full h-full flex items-center justify-center"
          >
            <a-spin>
              <a-icon
                slot="indicator"
                type="loading"
                style="font-size: 64px"
                spin
              />
            </a-spin>
          </div> -->
        </div>
      </div>

      <div v-if="management" class="my-8 mx-4 max-w-sm rounded-xl">
        <a-button
          type="primary"
          shape="round"
          @click.native="openModal"
          :block="true"
        >
          CREAR MUESTRA
        </a-button>
      </div>
      <Modal v-if="showModal">
        <div class="w-full px-4 flex justify-end items-center">
          <a-icon
            type="plus"
            class="text-2xl ml-auto transform text-gray-600 rotate-45"
            @click.native="closeModal"
          />
        </div>
        <div class="pb-4 flex flex-col justify-center items-center">
          <div class="mb-4 w-full max-w-md text-left">
            <h2 class="mb-2 text-2xl text-blue-dark"><b>Crear muestra</b></h2>
            <p class="text-md pb-0">
              Selecciona los datos para crear la muestra de monitoreo
            </p>
            <div class="w-full pb-4">
              <BaseSelect
                label="Entidad"
                :selectOptions="formOptions.optionsCompanies"
                v-model="sample_form.control_entity"
              />
              <BaseSelect
                label="Tipo de criterio a evaluar"
                :selectOptions="formOptions.optionsCriterions"
                v-model="sample_form.criterion"
              />
              <BaseNumber
                label="Cantidad de muestra"
                placeholder="Ej: 200"
                v-model="sample_form.sample_size"
              />
            </div>
          </div>

          <div class="w-full max-w-md flex flex-col gap-4">
            <a-button
              type="primary"
              :block="true"
              shape="round"
              @click.native="createSample"
            >
              CREAR MUESTRA
            </a-button>
            <a-button
              type="danger"
              :block="true"
              shape="round"
              ghost
              @click.native="closeModal"
            >
              CANCELAR
            </a-button>
          </div>
        </div>
      </Modal>
    </div>

    <div
      class="bg-white rounded-xl border border-gray-300 mt-4 ml-4 search-complaints"
    >
      <header
        class="header-derechos-peticion mb-4 flex flex-col-reverse items-center justify-center lg:flex-row lg:justify-end gap-3"
      >
        <a-dropdown class="mt-7 ml-5">
          <a-button
            type="primary"
            shape="round"
            @click="collapsed = !collapsed"
          >
            <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
          </a-button>
          <template #overlay>
            <!-- @click="handleMenuClick" -->
            <a-menu>
              <a-menu-item @click="downloadReport(1, 'xlsx')" key="xlsx">
                <a-icon
                  type="upload"
                  :style="{ fontSize: '16px' }"
                  class="mr-2"
                />
                .xlsx
              </a-menu-item>
              <a-menu-item @click="downloadReport(1, 'csv')" key="csv">
                <a-icon
                  type="upload"
                  :style="{ fontSize: '16px' }"
                  class="mr-2"
                />
                .csv (Separado por comas)
              </a-menu-item>
              <a-menu-item @click="downloadReport(1, 'txt')" key="txt">
                <a-icon
                  type="upload"
                  :style="{ fontSize: '16px' }"
                  class="mr-2"
                />
                .txt
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </header>

      <a-table
        class="table-component"
        :columns="columns"
        :data-source="data"
        :scroll="{ x: '1220px' }"
      >
        <span slot="nombre_cf" slot-scope="text, record">{{
          record.nombre_cf
        }}</span>
        <span slot="entidad" slot-scope="text, record">{{
          record.entidad
        }}</span>
        <span slot="codigo_queja" slot-scope="text, record">
          {{ record.codigo_queja }}
        </span>
        <span slot="texto_queja" slot-scope="text, record">
          {{ `${record.texto_queja.substring(0, 30)}...` }}
        </span>
        <!-- @click="goToReportDetail(record.document_id)" -->
        <div
          class="flex justify-evenly align-center"
          slot="action"
          slot-scope="text, record"
          @click="goDetail(record.codigo_queja)"
        >
          <button class="text-sm text-blue h-min">
            <a-icon
              type="eye"
              theme="twoTone"
              two-tone-color="#3366cc"
              :style="{ fontSize: '22px' }"
            />
            &nbsp;
            <span>Ver</span>
          </button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import TableComponent from "@/components/UI/Table/TableComponent.vue";
import ClassificationServicesImpl from "@/feature/clasification/data/services/derechos_peticion_services";
import TotalComplaints from "@/components/Dashboard/Complaints/TotalComplaints.vue";
import moment from "moment";

const columns = [
  {
    slots: { title: "nombre_cf" },
    title: "Nombre del CF",
    dataIndex: "nombre_cf",
    key: "nombre_cf",
    scopedSlots: { customRender: "nombre_cf" },
  },
  {
    slots: { title: "entidad" },
    title: "Entidad",
    dataIndex: "entidad",
    key: "entidad",
    scopedSlots: { customRender: "entidad" },
  },
  {
    slots: { title: "codigo_queja" },
    title: "Código interno",
    dataIndex: "codigo_queja",
    key: "codigo_queja",
    scopedSlots: { customRender: "codigo_queja" },
  },
  {
    slots: { title: "texto_queja" },
    title: "Contexto",
    dataIndex: "texto_queja",
    key: "texto_queja",
    scopedSlots: { customRender: "texto_queja" },
  },
  {
    slots: { title: "action" },
    title: "Acciones",
    dataIndex: "action",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  // props: ["management", "query", "loading", "duplicate"],
  props: ["management", "query", "duplicate"],
  components: {
    TableComponent,
    TotalComplaints,
  },
  data() {
    return {
      loading: false,
      count: 0,
      filterSearch: "",
      page: 1,
      pageSize: 100,
      pageSizeOptions: ["50", "100", "150", "200", "250"],
      columns: columns,
      data: [],
      form: {
        tipo_entidad: "",
        producto_cod: "",
        macro_motivo_cod: "",
        codigo_queja: "",
        insta_recepcion: "",
        start_date: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
        end_date: moment(new Date()).format("YYYY-MM-DD"),
        tipo_id_cf: "",
        nombre_cf: "",
        apoderado_nombre: "",
        numero_id_cf: "",
        // apoderado_correo: "",
        email_user: "",
        check_habeas_data: "",
        search: "", // Palabra clave
        company_sfc_code: "",
      },
      company: "",
      optionsProducts: [],
      optionsFavorability: [
        { name: "Favorable", id: 1 },
        { name: "Parcialmente Favorable", id: 2 },
        { name: "No Favorable", id: 3 },
      ],
      optionsReasons: [],
      productValue: "",
      reasonValue: "",
      favorabilityValue: "",
      sample_form: {
        control_entity: "",
        criterion: "",
        sample_size: "",
      },
      showModal: false,
      feedback: "",
      searching: false,
      disableButton: true,
      showFilters: false,
      oldFilters: {},
      // descargas componente
      collapsed: false,
      loadingReport: false,
    };
  },
  watch: {
    company: async function (value) {
      if (value == "") {
        this.form.entidad_cod = "";
        this.form.tipo_entidad = "";
        this.optionsProducts = [];
        this.optionsReasons = [];
        return;
      }
      this.productValue = "";
      this.reasonValue = "";
      let optionsProducts = await this.$store.dispatch(
        "form/loadProductsOptions",
        { company: this.company }
      );

      let optionsReasons = await this.$store.dispatch(
        "form/loadReasonsOptions",
        { company: this.company }
      );
      const companyData = this.$store.state.form.optionsCompanies.find(
        (item) => item.id == this.company
      );
      // console.log(companyData);
      this.form.company_sfc_code = companyData.sfc_code;
      this.form.tipo_entidad = companyData.company_type;
      this.optionsProducts = optionsProducts ? optionsProducts : [];
      this.optionsReasons = optionsReasons ? optionsReasons : [];
    },
    // feedback: function (value) {
    //   if (value == "") {
    //     console.log(value);
    //     this.feedback = "";
    //   }
    // },
  },

  methods: {
    async getReports() {
      this.loading = true;
      const { data } = await ClassificationServicesImpl.getClassifitionBySearch(
        this.form
      );
      this.data = data.data;
      this.count = data.count;
      this.loading = false;
    },
    async downloadReport(type, classFile) {
      const params = { ...this.form, class_type: type, get_file: classFile };
      // console.log(params);
      this.showNotification("Generando reporte");
      const { data } = await ClassificationServicesImpl.getReportClassifition(
        params
      );
      // console.log(data.url);
      const nameFile = "reporte.xlsx";
      const downloadLink = document.createElement("a");
      downloadLink.href = data.url;
      downloadLink.download = nameFile;
      downloadLink.click();
    },
    goDetail(id) {
      // console.log(id);
      this.$router.push(`/dashboard/classification/right_request/detail/${id}`);
    },
    async onSearch() {
      this.loading = true;
      const { data } = await ClassificationServicesImpl.getClassifitionBySearch(
        this.form
      );
      const result = data.data;
      this.data = result;
      this.loading = false;
      this.count = data.count;

      if (this.form.email_user && this.form.email_user !== "") {
        if (
          /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i.test(
            this.form.email_user
          )
        ) {
          this.feedback = "";
        } else {
          this.feedback = "Por favor, verifique el correo electrónico.";
        }
      }

      // this.searching = true;
      // console.log("(this.form.check_habeas_data)", this.form.check_habeas_data);
      // if (this.form.codigo_queja && this.form.codigo_queja !== "") {
      //   this.form.codigo_queja = this.form.codigo_queja.trim();
      // }

      // TO_DO: cambiar apoderado_correo por email_user //

      // if (this.form.apoderado_correo && this.form.apoderado_correo !== "") {
      //   if (
      //     /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i.test(
      //       this.form.apoderado_correo
      //     )
      //   ) {
      //     this.$emit("fetch-complaints", this.form);
      //     this.feedback = "";
      //   } else this.feedback = "Por favor, verifique el correo electrónico.";
      // } else if (this.changedFilters()) {
      //   this.$emit("fetch-complaints", this.form);
      //   this.oldFilters = this.form;
      //   this.feedback = "";
      // }
    },
    setRangeDate(eventValue) {
      this.form.start_date = eventValue[0];
      this.form.end_date = eventValue[1];
      this.disableButton = false;
    },
    // TO_DO: Modificar entidad_code
    getCompany() {
      // if (this.form.entidad_cod && this.form.entidad_cod !== "") {
      //   const companyData = this.$store.state.form.optionsCompanies.find(
      //     (item) =>
      //       item.sfc_code == this.form.entidad_cod &&
      //       item.company_type == this.form.tipo_entidad
      //   );
      //   this.company = companyData.id + "";
      // }
    },
    clearFilters() {
      this.form = {};
      this.$emit("fetch-complaints", this.form);
      this.setCompanyForEV();
      this.getReports();
    },
    setCompanyForEV() {
      this.company =
        this.$store.state.session.user.person.position == 21
          ? `${this.$store.state.session.user.id_company.id || ""}`
          : "";
    },
    changedFilters() {
      // let aKeys = Object.keys(this.form).sort();
      // let bKeys = Object.keys(this.oldFilters).sort();
      // if (aKeys.length != bKeys.length) return true;
      // for (let i = 0; i < aKeys.length; i++) {
      //   if (this.form[aKeys[i]] !== this.oldFilters[bKeys[i]]) {
      //     return true;
      //   }
      // }
      return true;
    },
    async createSample() {
      const { sample_size, control_entity, criterion } = this.sample_form;
      const body = {
        sample_quantify: parseInt(sample_size),
        entities: [parseInt(control_entity)],
        criterion: parseInt(criterion),
      };

      this.loading = true;
      let { error, data } = await this.$api.createSample(body);
      this.loading = false;

      if (error) this.showNotification(error.message);

      if (data) {
        this.showNotification("Muestra creada");
        this.$router.push(`/dashboard/complaints/management/samples`);
      }
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    showNotification(message) {
      this.$notification.open({
        // message: "Notificación",
        description: message,
      });
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
    disabled: function () {
      // let isFormEmpty = true;
      // Object.values(this.form).forEach((value) => {
      //   isFormEmpty = isFormEmpty && !value;
      // });
      // return isFormEmpty;
      return false;
    },
  },
  created() {
    this.getReports();
    this.form = { ...this.$props.query };

    this.oldFilters = { ...this.form };
    setTimeout(() => {
      this.getCompany();
      this.setCompanyForEV();
    }, 1500);
  },
};
</script>

<style scoped>
.header-derechos-peticion {
  justify-content: flex-start !important;
}

.header-derechos-peticion ul {
  border-bottom: none !important;
  background: none !important;
}

button {
  border-radius: 5px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.p-10 {
  padding: 10px;
}

.d-flex {
  display: flex;
}

.flex-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.ms-auto {
  margin-left: auto;
}

.btn-large {
  min-width: 300px;
}

.btn-small {
  min-width: 90px;
  padding: 3px;
}

.btn-white {
  box-shadow: 0 0 2mm #e7e7e7;
}

.btn-square {
  width: 40px;
  height: 40px;
}

.btn-square-small {
  width: 26px;
  height: 26px;
}

.btn-gray {
  background: #e7e7e7;
  color: black;
}

.btn-primary {
  background: #004884;
  color: white;
}

.btn-warning {
  background: #f8b446;
  color: white;
}

.btn-danger {
  background: #ea4a76;
  color: white;
}

.btn-success {
  background: #309d7e;
  color: white;
}

.btn-warning-light {
  background: #fff3e0;
  color: #f8b446;
}

.btn-danger-light {
  background: #ffedf2;
  color: #ea4a76;
}

.btn-primary-light {
  background: #edf3ff;
  color: #004884;
}

.btn-success-light {
  background: #e5fbf5;
  color: #309d7e;
}

.no-border {
  border: 0px !important;
}

.border-bottom-gray {
  border-bottom: 1px solid #e7e7e7;
}

.flag {
  min-width: 90px;
  display: flex;
  padding: 5px;
  border-radius: 5px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.search > input {
  border: 1px solid;
  border-radius: 10px;
  min-width: 400px;
  margin-right: 10px;
  padding: 0px 10px;
}

.search > button {
  border-radius: 10px;
}

.table {
  padding: 10px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  flex-wrap: wrap;
}

.tableHeader {
  font-weight: bold;
}

.table > div {
  flex-wrap: wrap;
}

.table > div > div {
  border-bottom: 1px solid black;
  padding: 10px 1px;
}

.progressbar {
  height: 26px;
  border-radius: 5px;
  background: #e5fbf5;
  display: flex;
  justify-content: start;
  align-content: center;
  align-items: center;
}

.progressbar > div {
  height: 26px;
  color: white;
  border-radius: 5px;
  background: #309d7e;
  width: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.w-45 {
  width: 45%;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

.w-30 {
  width: 30%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-15 {
  width: 15%;
}

.w-10 {
  width: 10%;
}

.w-5 {
  width: 5%;
}
</style>